import React from "react";
import theme from "theme";
import { Theme, Text, Section, Strong, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Englight English Academy
			</title>
			<meta name={"description"} content={"Ваш шлях до оволодіння англійською мовою."} />
			<meta property={"og:title"} content={"Englight English Academy"} />
			<meta property={"og:description"} content={"Ваш шлях до оволодіння англійською мовою."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-1.jpg?v=2024-05-30T08:45:54.394Z"} />
		</Helmet>
		<Components.QuarklycommunityKitBackToTop />
		<Components.Header111>
			<Override slot="text1" />
			<Override slot="quarklycommunityKitMenuOverride4" />
			<Override slot="quarklycommunityKitMenuOverride5" color="--primary" />
			<Override slot="text" />
		</Components.Header111>
		<Components.Gallery>
			<Override slot="Item 0" />
			<Override slot="Item 1" />
			<Override slot="Item 2" />
		</Components.Gallery>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="500px"
			background="--color-light"
			display="flex"
			flex-direction="column"
			sm-min-height="auto"
		>
			<Text
				as="h1"
				margin="8px 0px 32px"
				font="--headline2"
				md-font="--headline2"
				color="--dark"
				max-width="850px"
			>
				Ласкаво просимо до Englight English Academy, Вашого шляху до оволодіння англійською мовою.
			</Text>
			<Text
				margin="0px"
				width="50%"
				font="--base"
				color="--darkL1"
				md-width="100%"
				sm-margin="0px 0px 32px 0px"
			>
				Незалежно від того, чи ви готуєтеся до академічного просування, професійного розвитку чи особистого збагачення, наші курси адаптовані відповідно до вашого стилю навчання та цілей. Відкрийте для себе радість навчання з нашими досвідченими викладачами та інноваційними методами навчання.
			</Text>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			min-height="400px"
			sm-min-height="auto"
			background="--color-lightD2"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				font="--base"
				text-transform="uppercase"
				letter-spacing="1px"
				max-width="850px"
				color="--secondary"
				margin="0px 0px 8px 0px"
				width="100%"
			>
				Ми в Englight вважаємо, що вивчення мови має бути доступним, захоплюючим і ефективним.
			</Text>
			<Text
				as="h1"
				margin="0px"
				font="--headline2"
				md-font="--headline2"
				color="--dark"
				max-width="850px"
				width="50%"
				md-width="100%"
				md-margin="0px 0px 16px 0px"
			>
				Про Englight English Academy
			</Text>
			<Text
				margin="0px"
				font="--lead"
				color="--darkL1"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				Наша навчальна програма охоплює рівні від початкового до просунутого, охоплюючи широкий спектр тем і практичних застосувань. Кожен курс розроблений таким чином, щоб кинути виклик студентам, одночасно надаючи підтримку, необхідну для процвітання.
			</Text>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="400px"
			background="--color-light"
			display="flex"
			flex-direction="column"
			sm-min-height="auto"
		>
			<Text
				as="h1"
				margin="8px 0px 32px"
				font="--headline2"
				md-font="--headline2"
				color="--dark"
				max-width="850px"
			>
				Чому варто вибрати Englight?
			</Text>
			<Text
				margin="0px"
				width="90%"
				font="--lead"
				color="--darkL1"
				md-width="100%"
				sm-margin="0px 0px 32px 0px"
			>
				<Strong>
					Індивідуальне навчання
				</Strong>
				: курси адаптовані відповідно до ваших індивідуальних цілей і темпу навчання.
				<br />
				<br />
				<Strong>
					Досвідчені інструктори
				</Strong>
				: вчіться у лінгвістів і педагогів, які захоплені навчанням і успіхом.
				<br />
				<br />
				<Strong>
					Гнучкий розклад
				</Strong>
				: приєднуйтеся до занять у той час, який відповідає вашому напруженому стилю життя, з можливістю вечірніх курсів і курсів у вихідні дні.
				<br />
				<br />
				<Strong>
					Культурне занурення
				</Strong>
				: беріть участь у заходах та уроках, які не лише навчать мови, але й занурюють вас у культурні нюанси англомовних країн.
			</Text>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-lightD2" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				Поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						<Strong>
							1. Які курси ви пропонуєте?
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ми пропонуємо різноманітні курси, включаючи загальний англійський, діловий англійський, підготовку до іспитів (IELTS, TOEFL, Cambridge), англійську для дітей та підлітків, а також спеціалізовані курси для різних професій.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						<Strong>
							2. Як довго триває курс?
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Тривалість курсу залежить від його типу. Загальні курси можуть тривати від 3 до 6 місяців, підготовчі курси до іспитів – від 2 до 4 місяців, а спеціалізовані курси – від 1 до 3 місяців.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						<Strong>
							3. Чи можу я почати навчання в будь-який час?
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми пропонуємо гнучкий графік, що дозволяє студентам приєднатися до курсів у будь-який час. Однак, для деяких курсів є встановлені дати початку.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						<Strong>
							4. Як зареєструватися на курс?
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Для реєстрації телефонуйте за номером 0322600416.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						<Strong>
							5. Яка вартість курсів?
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Вартість залежить від типу курсу та його тривалості. Для отримання детальної інформації, будь ласка, зверніться до розділу "Ціни" на нашому вебсайті або зв'яжіться з нашим офісом.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						<Strong>
							6. Чи є можливість оплати частинами?
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми пропонуємо можливість розстрочки платежу. Подробиці можна дізнатися у нашого адміністратора під час реєстрації.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong>
								7. Чи отримають студенти сертифікат після закінчення курсу?
							</Strong>
							ість курсів?
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, всі студенти отримують сертифікат про проходження курсу після успішного завершення навчання.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="box10" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text18" />
			<Override slot="text16">
				0322600416
			</Override>
			<Override slot="button" />
			<Override slot="text17" />
			<Override slot="box9" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="text6" />
			<Override slot="text8" />
			<Override slot="text10" />
			<Override slot="text12" />
			<Override slot="text14" />
			<Override slot="icon" />
		</Components.Contacts>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ba3ab38e2e8e002177087f"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});